<!-- chenbang >>> 2023/7/12 18:27:14 -->
<template>
  <div class="swapper">
    <div class="card-style-1">
      <div class="left">
        <p class="title">雅思全真机考模拟</p>
        <p class="desc">
          1:1高度还原雅思官方机考界面及功能<br/>
          剑桥雅思4~19，60套A类真题免费使用，<br/>
          备战雅思，有趴趴就够了
        </p>
        <el-button class="btn" type="success" round @click="toGo">立即开始</el-button>
      </div>
      <img class="right" :src="`${domain}web/IELTS-PIC1.png`" alt="">
    </div>
    <div class="card-style-2">
      <img class="left" :src="`${domain}web/IELTS-PIC2.png`" alt="">
      <div class="right">
        <p class="title">剑雅真题逐题精析！</p>
        <p class="desc">
          听力阅读 —— 名师团队逐题深度解析，自主<br/>
          刷题不留疑问计时做题不方便？<br/>
          写作部分 —— 王牌导师撰写高分范文，把握<br/>
          高分写作逻辑
        </p>
      </div>
      <img class="bg-img" :src="`${domain}web/mock_bg1.png`" alt="">
    </div>
    <div class="card-style-3">
      <div class="left">
        <p class="title">海量题库精细分类！</p>
        <p class="desc">
          支持分题型、分话题、分难度练习<br/>
          训练相同题型答题技巧，开拓相似话题答题<br/>
          思路
        </p>

      </div>
      <img class="right" :src="`${domain}web/IELTS-PIC3.png`" alt="">
    </div>
    <div class="card-style-4-anim">
      <img class="left" :src="`${domain}web/IELTS-PIC4.png`" alt="">
      <div class="right">
        <p class="title">听力精听，一键定位音频！</p>
        <p class="desc">
          反复精听，实现听力能力高效提升<br/>
          重点句子可收藏，7天、30天、90天定期回<br/>
          顾，彻底攻破听力难句
        </p>

      </div>
      <img class="bg-img" :src="`${domain}web/mock_bg2.png`" alt="">
    </div>
  </div>
</template>

<script>
const domain = process.env.VUE_APP_IMAGE_DOMAIN

export default {
  name: 'IELTS',
  components: {},
  props: {},
  data() {
    return {
      domain
    }
  },
  methods: {
    toGo() {
      window.open('https://ielts.papaen.com', '_blank')
    }
  }

}
</script>

<style lang="less" scoped>
@import "./style";
</style>
